<!--日销售数据统计-->
<template>
    <div class="dailySalesDetailReport">
        <reportTable
            code="SalePriceDetails"
            :query="query"
            @generateComplete="generateComplete"
            @exportTable="exportTable"
            :percentage="percentage"
            custom-export
        >
            <template #queryItems>
                <div class="query-item">
                    <label class="name">场站：</label>
                    <el-select
                        v-model="query.station_id"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
            </template>
            <template #customTable>
                <div
                    class="table-box"
                    id="download-excel"
                    v-loading="loading"
                    style="height: 100%"
                >
                    <div class="table-head">
                        <table class="t-table" cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style="width: 1rem" rowspan="2">
                                        日期
                                    </th>
                                    <th style="width: 1.5rem" rowspan="2">
                                        站点
                                    </th>
                                    <th colspan="3">
                                        本日数据
                                    </th>
                                    <th colspan="4">
                                        销售方式
                                    </th>
                                </tr>
                                <tr>
                                    <th style="width: 1.1rem">
                                        本日销量
                                    </th>
                                    <th style="width: 1.1rem">
                                        本日应收
                                    </th>
                                    <th style="width: 1.1rem">
                                        本日含税均价
                                    </th>

                                    <th style="width: 1.1rem">
                                        现款方量
                                    </th>
                                    <th style="width: 1.1rem">
                                        现款均价
                                    </th>
                                    <th style="width: 1.1rem">
                                        赊销方量
                                    </th>
                                    <th style="width: 1.1rem">
                                        赊销均价
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table-body">
                        <table
                            class="t-table"
                            v-if="tableList.length>0"
                            cellpadding="0"
                            cellspacing="0"
                        >
                            <tbody>
                                <template v-for="(item1,index1) in tableList">
                                    <tr :key="index1">
                                        <td
                                            v-if="mergeCells['data_date'+index1]!=0"
                                            :rowspan="mergeCells['data_date'+index1]"
                                            style="width: 1rem"
                                        >
                                            {{ item1.data_date }}
                                        </td>
                                        <td style="width: 1.5rem">
                                            {{ item1.station_name }}
                                        </td>
                                        <td style="width: 1.1rem">
                                            {{ item1.today_sales_volume }}
                                        </td>
                                        <td style="width: 1.1rem">
                                            {{ item1.today_receivable }}
                                        </td>
                                        <td style="width: 1.1rem">
                                            {{ item1.today_average_price_including_tax }}
                                        </td>
                                        <td style="width: 1.1rem">
                                            {{ item1.cash_volume }}
                                        </td>
                                        <td style="width: 1.1rem">
                                            {{ item1.cash_average_price }}
                                        </td>
                                        <td style="width: 1.1rem">
                                            {{ item1.sell_on_credit_volume }}
                                        </td>
                                        <td style="width: 1.1rem">
                                            {{ item1.sell_on_credit_average_price }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <div class="el-table__empty-text" v-if="tableList.length == 0"></div>
                    </div>
                </div>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import OMS from '@/assets/js/exportExcel/exportTools';
import utils from '@/utils/util';
// import qs from 'qs';
export default {
    components: { reportTable },
    data() {
        return {
            loading: false,
            query: {
                station_id: '',
            },
            stationList: [],
            contractTypes: [],
            tableTitle: '日销售数据统计',
            tableList: [],
            // 分批加载 大小
            limit: 10,
            // 进度条
            percentage: 100,
            // 合并单元格配置
            mergeCells: {},
        };
    },
    created() {
        this.getStationList();
    },
    methods: {
        // 获取场站列表
        async getStationList() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/stationmanger/get_station_list');
            if (data.length > 0) {
                this.stationList = data;
            }
        },
        // 报表生成完成
        async generateComplete(params) {
            try {
                this.queryArr = null;
                this.loading = true;
                this.tableList = [];
                this.percentage = 0;
                const startTimes = new Date(params.startTime).getTime();
                const endTimes = new Date(params.endTime).getTime();
                const splitTimes = this.limit * 24 * 60 * 60 * 1000;
                const arr = [];
                for (let times = startTimes; times <= endTimes; times += splitTimes) {
                    const start = utils.timeFormat(new Date(times), 'yyyy-MM-dd');
                    let endms = times + splitTimes;
                    if (endms >= endTimes) {
                        endms = endTimes;
                    }
                    const end = utils.timeFormat(new Date(endms), 'yyyy-MM-dd');
                    if (arr.length > 0 && start === end) {
                        break;
                    }
                    arr.push([start, end]);
                }
                this.queryArr = arr;
                const url = '/interfaceApi/report/daily_sales_details/get_daily_sales_total';
                for (const i in arr) {
                    if (this.queryArr !== arr) {
                        return;
                    }
                    const pam = Object.assign( {}, params);
                    pam.startTime = arr[i][0];
                    pam.endTime = arr[i][1];
                    const listUrl = '/interfaceApi/report/daily_sales_details/get_daily_sales_details';
                    const list = await this.$axios.post(listUrl, pam);
                    this.percentage = parseInt((Number(i) + 1) / arr.length * 100, 0);
                    const pushList = [];
                    list.forEach(item => {
                        item.details_list.forEach(row => {
                            row.data_date = item.data_date;
                            pushList.push(row);
                        });
                        pushList.push({
                            data_date: item.data_date,
                            station_name: '合计',
                            today_sales_volume: item.today_sales_volume_subtotal,
                            today_receivable: item.today_receivable_subtotal,
                            today_average_price_including_tax: '',
                            cash_volume: item.cash_volume_subtotal,
                            cash_average_price: '',
                            sell_on_credit_volume: item.sell_on_credit_volume_subtotal,
                            sell_on_credit_average_price: '',
                        });
                    });
                    this.tableList.push(...pushList);
                    this.mergeCellsFn(this.tableList);
                    if (Number(i) === 0) {
                        this.loading = false;
                    }
                }
                if (this.tableList.length > 0) {
                    const totalData = await this.$axios.post(url, params);
                    this.tableList.push({
                        data_date: '合计',
                        station_name: '',
                        today_sales_volume: totalData.today_sales_volume_total,
                        today_receivable: totalData.today_receivable_total,
                        today_average_price_including_tax: '',
                        cash_volume: totalData.cash_volume_total,
                        cash_average_price: '',
                        sell_on_credit_volume: totalData.sell_on_credit_volume_total,
                        sell_on_credit_average_price: '',
                    });
                    this.mergeCellsFn(this.tableList);
                }
                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
        sliceArr(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            return res;
        },
        // 合并单元格方法
        mergeCellsFn(list = []) {
            const mergeProps = ['data_date'];
            list.forEach((item, index) => {
                for (const i in mergeProps) {
                    const prop = mergeProps[i];
                    const prevProp = mergeProps[i - 1];
                    const mergeKey = prop + '' + index;
                    if (this.mergeCells[mergeKey] || !item[prop]) {
                        continue;
                    }
                    let rowspan = 1;
                    let nextIndex = index + 1;
                    // eslint-disable-next-line max-len
                    while (list[nextIndex] && item[prop] === list[nextIndex][prop] && item[prevProp] === list[nextIndex]?.[prevProp] ) {
                        // 合并条件需要前面所有列也相同
                        let pass = true;
                        for (let m = 0; m < i; m++) {
                            const po = mergeProps[m];
                            if (item[po] !== list[nextIndex]?.[po]) {
                                pass = false;
                                break;
                            }
                        }
                        if (!pass) {
                            break;
                        }
                        rowspan++;
                        nextIndex++;
                    }
                    this.mergeCells[mergeKey] = rowspan;
                    let prevIndex = index - 1;
                    // eslint-disable-next-line max-len
                    while (list[prevIndex] && item[prop] === list[prevIndex][prop] && item[prevProp] === list[prevIndex]?.[prevProp] ) {
                        // 合并条件需要前面所有列也相同
                        let pass = true;
                        for (let m = 0; m < i; m++) {
                            const po = mergeProps[m];
                            if (item[po] !== list[prevIndex]?.[po]) {
                                pass = false;
                                break;
                            }
                        }
                        if (!pass) {
                            break;
                        }
                        rowspan++;
                        prevIndex--;
                        this.mergeCells[mergeKey] = 0;
                        break;
                    }

                }
            });
        },
        exportTable() {
            OMS.downLoadXlsx({
                dom: 'download-excel',
                name: this.tableTitle,
                ColumnWdth: [80, 150, 200, 200],
            });
        },
    },
    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>

</style>

